import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/web_proj1.css"
import { Link } from "gatsby"
import { FaCode, FaLink } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const nlp_model2 = () => {
  return (
    <Layout>
      <SEO
        title="Restotante Con Fusion"
        description="Full Fledged Awesome Website Development (Web-Dev) project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/nlp_model/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Models</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="nlpmodel-img2"> 
          <div className="py-1-heading">
            <h1>Yelp Business Reviews</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Natural-Language-Processing/tree/master/Yelp%20Business%20Reviews%20-%20NLP"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        
        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It:</h3>
            <p>
               We will be attempting to classify Yelp Reviews into 1 star or 5 star categories based off the text content in the reviews.
               <br/>
              <br />
              Model is made on yelp dataset. Each observation in this dataset is a review of a particular business by a particular user.
              <br/>
              <br/>
              Starting with pre-processeing like Vectorization, Tfidf Transformer etc.
              <br/>
              Continue with EDA to explore the data.
              <br/><br/>
              
              Training model with  Multinomial Naive Bayes algorithm.
              <br/>
              Checking for classification report.
               <br/>
                <br/>
              Also Creating a Data Pipeline to store a pipeline of workflow.
              <br />
            </p>

            <h3>Skills Behind It:</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Pandas</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Numpy</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Matplotlib</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Seaborn</span>
              </button> 
              <button type="button" className="btn-py-1" disabled>
                <span>Sklearn</span>
              </button> 
              <button type="button" className="btn-py-1" disabled>
                <span>NLTK</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>TfidfTransformer</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Multinomial Naive Bayes</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Pipeline</span>
              </button>

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default nlp_model2
